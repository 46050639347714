/* Color Palette */
/* #57E0E9 - Electric Blue */
/* #020231 - Oxford Blue */
/* #F4743B - Orange (Crayola) */
/* #FFFFFF - White */

@import url('https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap');

.OB {
  background-color: #020231 !important;
  color: #FFF !important;
}

.App {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

* {
  font-family: 'ZCOOL XiaoWei', sans-serif !important;
}

a{
  text-decoration: none;
}

.rc-anchor-light.rc-anchor-normal, .rc-anchor-light.rc-anchor-compact {
  border: 1px solid #d3d3d3;
  display: none;
}

.testimonial-item {
  text-align: center;
}

.slick-dots {
  left: -1%;
}

form input {
  line-height: 6rem;
}

button {
  cursor: pointer;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 2% !important;
  justify-content: center !important;
  border-radius: 10px !important;
}

.center {
  text-align: center;
}

/* NavBar */

.Navbar {
  padding: 1% 5% 0%;
  position: fixed;
  z-index: 9999;
  background-color: #ffffff;
  height: fit-content;
  width: 90dvw;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Navbar img {
  width: 11vw;
}

img.MobileLogo {
  width: 35vw;
}

.Navbar ul {
  display: none;
}

.Navbar button {
  background-color: #68e0e9;
  border: none;
  border-radius: 50px;
  color: black;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  height: 4.5vh;
  width: 35vw;
}

.Navbar li {
  cursor: pointer;
}

img.pro{
  display: none;
}

/* SideBar */

.test {
  width: 100%;
  height: 110dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3%;
  height: 100%;
  width: 100%;
  margin-top: 40%;
}

.close-sidebar {
  float: right;
  font-size: xx-large;
}

.Sidebar img {
  width: 50px;
}

.Sidebar li {
  font-size: 1.2rem;
  list-style-type: none;
}

.Sidebar .sign-up {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.Sidebar img.social.logos {
  width: 50px;
  margin: unset;
}

.hamburger {
  color: #f4743b;
  font-size: xx-large;
}

/* Hero */

.Herocontainer {
  display: flex;
  margin-top: 20%;
  flex-direction: column;
  padding: 0 5%;
  width: 90%;
  align-items: center;
}

.quarter {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QUHEAD {
    font-size: 30px;
    text-align: center;
}

.LightBlueText {
    font-size: 20px;
}

.blue-text {
    color: #57e0e9;
}

.orange-text {
    color: #f4743b;
}

.QPARA {
    text-align: center;
}

.quarter .contentbox {
    height: 150px;
    width: 100%;
    padding: 0%;
}

.quarter .HeroCTA {
    background-color: #020231;
    border: 1px #020231;
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    height: 4vh;
    width: 35vw;
    padding: 2%;
}

/* Main Section */

.MainContent {
  padding: 0 5% 0 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p#main {
  padding-bottom: 15%;
}

#main{
  text-align: center;
}

img.CompanyLogo {
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

/* Main CTA Box 1 */

.container1{
  margin-bottom: 10%;
}

.container1, .container2 {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  align-items: center;
}

.half {
  flex: 1;
}

.container1 h1, .container2 h1 {
    margin: unset;
}

.container1 #cta1 {
  margin-bottom: 10%;
}

.container1 #cta1, .container2 #cta1  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #57e0e9;
    border: 2px solid #57e0e9;
    border-radius: 32px;
    color: #000;
    font-size: 18px;
    height: 4vh;
    width: 65vw;
    align-items: center;
}

.container1 .half img, .container2 .half img {
    width: 280px;
    border-radius: 5%;
    margin-bottom: 10%;
    border: 5px solid #f4743b;
}

.container1 .half_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.container2 .half_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.container1 .half_text h1, .container2 .half_text h1 {
    font-size: 2em;
}

.half_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container1 .half_text p, .container2 .half_text p {
    font-size: 1em !important;
    padding: 6% 0;
}

/* Secondary Section */

.SecondaryContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0 5%;
  width: 90%;
}

.SecondaryContent h1 {
  font-size: 48px;
  font-weight: 400;
  text-align: center;
}

.SecondaryContent p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  margin-top: 10%;
}

.SecondaryContent #leftbox1 {
  background-color: #57e0e9;
  bottom: 30px;
  box-shadow: -20px 40px 0 0 #020231;
  position: relative;
  left: 0.5rem;
}

.left-div, .right-div {
  border: 2px solid #000;
  border-radius: 15px;
  box-sizing: border-box;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 5%;
  position: relative;
  right: 0.5rem;
  width: 100%;
  margin-top: 10%;
}

.left-div img, .right-div img {
  width: 6vw;
  margin-right: 5%;
}

.left-div h2, .right-div h2 {
  align-items: center;
  display: flex;
  font-size: 26px !important;
  margin: 0;
  text-align: left;
}

/* Contact Form */

.Contact-Us{
  width: 90%;
  padding: 0 5% 0 5%;
}

.Contact-Us img {
  width: 100%;
}

.form-row {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}

.form-row input {
  height: 5vh;
  line-height: 6rem;
}

.form-row input, .form-row textarea {
  border: 2px solid #aaa98d;
  border-radius: 10px;
  font-family: ZCOOL XiaoWei,sans-serif;
  font-size: 14px;
  height: 3vh;
  margin-right: 10px;
  padding-left: 10px;
  text-indent: 2%;
  width: 100%;
}

.form-row textarea {
  height: 100px;
  padding: 15px 2% 2% 0;
  width: 635px;
}

label.ATC {
  font-size: 1rem;
  width: 90vw;
  display: flex;
  align-items: center;
}

input#checkBox {
  float: left;
  width: 8vw;
}

input[type=checkbox]{
  accent-color:  #020231;  
  }

.form-container button {
  align-items: center;
  background-color: #f4743b;
  border: 2px solid #f4743b;
  border-radius: 2rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 26px;
  height: 6vh;
  justify-content: center;
  width: 100%;
}

.loading svg {
  display: inline-block;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p.loading {
    align-items: center;
    background-color: #f4743b;
    border: 2px solid #f4743b;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 26px;
    height: 4vh;
    justify-content: center;
    width: 100%;
}

/* Testimonials */


.Testimonials {
  padding: 0 5% 0 5%;
  width: 90%;
}

.carousel-container {
  background: #57e0e9;
  border-radius: 25px;
  padding: 5%;
}

.carousel-arrow.next, .carousel-arrow.prev {
  display: none;
}

.carousel-arrow.next, .carousel-arrow.prev {
  display: none;
}

/* FAQ */

.FAQ {
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  width: 90%;
  padding: 0 5% 0 5%;
}

.FAQ h1 {
  font-size: 2em;
  text-align: center;
}

.faq-container {
  margin: 25% auto auto;
  width: 80%;
}

.faq-item {
border-bottom: 2px solid;
margin-bottom: 40px;
}

.question {
  margin-bottom: 20px;
}

span.toggle-button {
  bottom: 1rem;
  float: right;
  left: 9%;
  position: relative;
}

.toggle-button img {
  width: 8vw;
}

.answer {
  margin-bottom: 10px;
  margin-top: 40px;
}

/* Newsletter */

.Newsletter {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 90%;
  padding: 0 5% 0 5%;
}

.Newsletter-container {
  background-color: #f4743b;
  border-radius: 15px;
  height: fit-content;
  width: 90%;
  padding: 2%;
}

.Newsletter-container h1 {
  color: #fff;
  position: relative;
  font-size: 1.6rem;
  padding: 5%;
}

div#mc_embed_shell {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: unset;
  height: fit-content;
  align-items: flex-end;
}

#mc_embed_signup {
  position: relative;
  background: unset !important;
  color: black !important;
  clear: left !important;
  font: 1rem 'ZCOOL XiaoWei', sans-serif !important;
  width: 100% !important;
  left: unset !important;
}

#mc_embed_signup .mc-field-group input {
  border-radius: 30px;
  height: 20px;
}

#mc_embed_signup .button {
  align-items: center;
  background-color: #020231!important;
  display: flex!important;
  flex-direction: column;
  font-size: 1rem!important;
  height: 4vh!important;
  width: 30vw !important;
  justify-content: center;
  line-height: inherit!important;
  margin: auto!important;
  padding: 0!important;
  text-align: center!important;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  color: #fff !important;
  background: transparent !important;
  font-size: 1rem !important;
}

#mc_embed_signup #mce-success-response {
  color: #fff !important;
  display: none;
  margin-top: 0em;
}

div#mce-responses {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#mc_embed_signup form {
  margin: 0px !important;
  text-align: unset;
}

/* Footer */

.Footer {
  background-color: #57e0e9;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  position: relative;
  width: 100%;
  padding: 5%;
}

.Footer-container {
  margin: auto;
  width: 90%;
}

.footer-section {
  text-align: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20%;
}

.footer-section h1, h2, h3 {
  margin: 0px;
}

.logos {
  margin-top: 7%;
}

img.footer-logo {
  margin-bottom: 0px;
  width: 128px;
}

.footer-section .footer-logo, h2 {
  margin-bottom: 30px;
  height: 35px;
}

.footer-section h3 {
  margin-bottom: 20px;
}

img.social.logos {
  height: 25px;
  margin-right: 20px;
  width: 25px;
}

@media (max-width: 319px) {
  .HeroSection {
    margin-top: 20%;
  }
  
  .container1 .half_img, .container2 .half_img {
    display: none;
  }

  .container1 #cta1, .container2 #cta1 {
    height: 5vh;
    width: 70vw;
  }
}

@media (max-height: 649px) {
  .Mac{
    display: none;
  }

  #iPhone{
    display: none;
  }

  .MainCTA1 .buttonContainer {
    margin: auto;
    right: unset !important;
  }

  .MainCTA1 p {
    text-align: center;
  }

  .Main1Text {
    display: flex;
    width: 100% !important;
    margin-top: 0% !important;
    flex-direction: column;
    align-items: center;
  }

  .MainCTA2 #promptbox {
    display: none;
  }

  .MainCTA2{
    text-align: center;
    align-items: center;
  }  

  .MainCTA2 h1 {
    width: 100% !important;
  }

  .MainCTA2 .buttonContainer {
    right: 0%;
  }

  .MainCTA2 .buttonContainer {
    right: 0% !important;
  }

  .MainCTA3 {
    text-align: center;
  }

  .MainCTA1 #cta1, .MainCTA1 #cta2 {
    height: 10vh !important;
  }

  .MainCTA3 img {
    display: none !important;
  }

  .image-5{
    display: none !important;
  }

  .HeroText {
    font-size: 1.5rem;
    grid-row: 1;
    margin: 0;
    width: 100% !important;
  }

  .MainCTA3 {
    display: flex !important;
    align-items: center !important;
  }

  .MainCTA3 .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center !important;
  }

  #mc_embed_signup .button {
    background-color: #020231!important;
    font-size: 1.5rem !important;
    height: 7vh!important;
    line-height: inherit!important;
    margin: auto!important;
    padding: 2%!important;
    text-align: center!important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: unset !important;
  }

}

@media (min-width: 320px) {

  .HeroSection, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    margin-top: 12%;
  }

  .Navbar {
    padding: 2% 5%;
  }

  .HeroSection{
    margin-top: 20%;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    text-wrap: pretty;
    margin-top: unset;
    margin-bottom: unset;
  }

  h2, h3{
    font-size: 26px;
    text-wrap: pretty;
  }

  p, .question-text, .answer {
    font-size: 1rem !important;
    text-wrap: pretty;
  }

  .Main1Text {
    margin-top: 60%;
  }
}

@media (min-width: 375px) {
  .container1 .half img, .container2 .half img {
    width: 340px;
    border-radius: 5%;
    margin-bottom: 10%;
  }

  .Sidebar {
    margin-top: 30%;
  }
}

@media (min-width: 425px) {
  .container1 .half img, .container2 .half img {
    width: 380px;
    border-radius: 5%;
    margin-bottom: 10%;
  }

  .quarter .contentbox {
    height: 200px;
    width: 100%;
    padding: 0%;
  }

  .Newsletter-container {
    background-color: #f4743b;
    border-radius: 15px;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {

  .HeroSection, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    margin-top: 10%;    
  }

  .Contact-Us, .FAQ, .Footer, .HeroSection, .MainContent, .Navbar, .Newsletter, .SecondaryContent, .Testimonials {
    width: 90%;
  }

  .Main1Text {
    width: 45%;
    margin-top: 0%;
  }

  .Navbar button {
    width: 15vw;
  }

  .Navbar img {
    width: 7vw;
  }

  img.CompanyLogo {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  img.MobileLogo {
      width: 15%;
  }

  .DesktopHerocontainer {
      padding: 0% 5%;
      display: flex;
      flex-direction: row;
      margin-top: 10%;
      align-items: center;
      gap: 5%;
      justify-content: space-between;
  }

  img.DesktopContentbox {
      width: 100%;
  }

  .DesktopQuarter {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .TabletHerocontainer {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 5%;
  }

  .TabletQPARA {
      font-size: 1.3rem !important;
      margin-bottom: 10%;
  }

  img.Tabletimage-5 {
      display: block;
      width: 40vW;
      margin: 10% 0;
  }

  .TabletMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 15vh;
  }

  img.TabletCompanyLogo {
      width: 80%;
      margin-top: 0%;
      margin-bottom: 0%;
  }

  img.TabletContentbox {
    width: 15vw;
    position: relative;
    right: 0%;
    top: -12vh;
    height: 25vh;
  }

  img.image-5 {
    display: block;
    width: 40vW;
    margin: 10% 0;
  }

  .HeroCTA {
    background-color: #020231;
    border: 1px #020231;
    color: #fff;
    font-size: 1rem;
    height: 4vh;
    width: 35vw;
  }

  .Sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3%;
      height: 100%;
      width: 100%;
      margin-top: 10%;
  }

  .HeroImageButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  img.contentbox {
    padding-top: 0%;
  }

  p#main {
    padding-bottom: 0%;
  }

  .Orange-Box {
    background: #f4743b;
    padding: 3%;
    border-radius: 10px;
    margin-bottom: 5%;
  }
  
  .container1{
    margin-bottom: 10%;
  }

  .container1, .container2 {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
  }
  
  .half {
    flex: 1;
  }
  
  .container1 h1, .container2 h1 {
      margin: unset;
  }
  
  .container1 #cta1, .container2 #cta1  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #57e0e9;
      border: 2px solid #57e0e9;
      border-radius: 32px;
      color: #000;
      font-size: 18px;
      height: 4vh;
      width: 28vw;
      align-items: center;
  }
  
  .container1 .half img, .container2 .half img {
      width: 280px;
      border-radius: 5%;
      margin-bottom: unset;
  }
  
  .container1 .half_img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
  }
  
  .container2 .half_img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  }
  
  .container1 .half_text h1, .container2 .half_text h1 {
      font-size: 2em;
  }
  
  .half_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .container1 .half_text p, .container2 .half_text p {
      font-size: 1em !important;
      padding: 6% 0;
  }

  .Contact-Us, .SecondaryContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    width: 90%;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    position: relative;
    width: 50%;
  }

  .Contact-Us img {
    width: 80%;
  }

  .FormContainer {
    width: 80%;
  }

  .faq-container {
    margin: 10% auto auto;
    width: 100%;
  }

  .toggle-button img {
    width: 4vw;
  }

  span.toggle-button {
    bottom: 1rem;
    float: right;
    left: 0%;
    position: relative;
  }

  .Newsletter {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    padding: 0 5% 0 5%;
  }

  .Newsletter-container h1 {
    color: #fff;
    position: relative;
    font-size: 1.5rem;
    padding: 0;
  }

  .Newsletter-container {
    background-color: #f4743b;
    border-radius: 15px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  div#mc_embed_shell {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: unset;
    height: fit-content;
    align-items: center;
}

  #mc_embed_signup {
    position: relative;
    color: black !important;
    clear: left !important;
    font: 1rem 'ZCOOL XiaoWei', sans-serif !important;
    width: 90% !important;
    left: unset !important;
  }

  #mc_embed_signup .button {
    background-color: #020231 !important;
    padding: 0% !important;
    font-size: 1rem !important;
    text-align: center !important;
    height: 3vh !important;
    line-height: unset !important;
    margin: auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 15vw !important;
  }

  .Footer-container {
    margin: auto;
    width: 40%;
  }

}

@media (min-width: 1024px) {

  .App{
    gap: 0rem;
  }

  .HeroSection, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    margin-top: 9%;
  }

  p, .question-text, .answer{
    font-size: 1.4rem;
  }

  h2, h3 {
    font-size: 1.2rem !important;
  }

  .Navbar, .HeroSection, .MainContent, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    width: 90%;
  }
  
  .Navbar {
    padding: 1% 5%;
  }

  .Navbar li {
    align-items: center;
    display: flex;
    position: relative;
  }

  .Navbar ul {
    display: flex;
    font-size: 1.3em;
    list-style-type: none;
    margin-left: 0px;
    padding: 0;
  }

  .Navbar img {
    width: 7vw;
  }

  .Navbar button {
    width: 10vw;
    font-size: 1.2rem;
  }

  .P-Text {
    padding: 5% 0px 5%;
    font-size: 1.4rem;
  }

  .non-Pro {
    cursor: pointer;
    margin: 0px 1rem;
  }

  .li-Pro {
      margin-right: 3rem;
  }

  .DesktopHerocontainer {
    margin: 10% 0 5% 0;
  }

  img.image-5 {
      display: block;
      width: 35vw;
  }

  .HeroCTA {
      background-color: #020231;
      border: 1px #020231;
      color: #fff;
      font-size: 1rem;
      height: 4vh;
      width: 15vw;
  }

  img.DesktopContentbox {
    width: 15vw;
    position: relative;
    right: 35%;
  }

  .DesktopHerocontainer {
      padding: 0% 0% 0% 6%;
  }

  .DesktopQuarter2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .DesktopQPARA {
    margin: 8% 0% 10% 0%;
  }

  .Orange-Box {
      background: #f4743b;
      padding: 3%;
      border-radius: 10px;
  }

  .DesktopQPARA {
      margin-bottom: 10%;
  }

  .Sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3%;
      height: 100%;
      width: 100%;
      margin-top: 25%;
  }

  .MainHeading {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5%;
      gap: 5%;
  }

  .MainHeading div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.DesktopCompanyLogo {
    width: 105%;
    margin-bottom: 7%;
  }

  p#main {
    padding-bottom: 0% !important;
    color: #fff;
  }

  .container1, .container2 {
    width: 80%;
  }

  .container1 .half img, .container2 .half img {
    width: 80%;
  }

  .container1 .half_text h1, .container2 .half_text h1 {
    font-size: 2.3em;
  }

  .container1 .half_text p, .container2 .half_text p {
    font-size: 1.1em !important;
    padding: 6% 0;
  }

  .container1 #cta1, .container2 #cta1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #57e0e9;
    border: 2px solid #57e0e9;
    border-radius: 32px;
    color: #000;
    font-size: 1rem;
    height: 5vh;
    width: 20vw;
    align-items: center;
  }

  .Testimonials {
    padding: 0 5% 0 5%;
  }

  .Contact-Us {
    padding: 0 5% 0 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Contact-Us img {
    position: relative;
    width: 44%;
    bottom: 3.5vh;
  }

  .FormContainer {
    position: relative;
    width: 45%;
    left: 10%;
  }

  .form-row input {
    line-height: 6rem;
  }

  .form-row input, .form-row textarea {
    border: 2px solid #aaa98d;
    border-radius: 10px;
    font-family: ZCOOL XiaoWei,sans-serif;
    font-size: 14px;
    height: 5vh;
    margin-right: 10px;
    text-indent: 2%;
    width: 100%;
  }

  .SecondaryContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0 5% 0 5%;
  }

  .SecondaryContent p {
    font-size: 1.3rem;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    margin-top: 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .SecondaryContent #leftbox1 {
    background-color: #57e0e9;
    bottom: 30px;
    box-shadow: -20px 40px 0 0 #020231;
    left: 20px;
    position: relative;
  }

  .left-div, .right-div {
    border: 2px solid #000;
    border-radius: 15px;
    box-sizing: border-box;
    height: 235px;
    padding: 2%;
    width: 45%;
  }

  .left-div img, .right-div img {
    width: 3vw;
  }

  .toggle-button img {
    width: 2vw
  }

  .Newsletter {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0 5% 0 5%;
  }

  .Newsletter-container {
    background-color: #f4743b;
    border-radius: 15px;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  div#mc_embed_shell {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-end;
  }

  #mc_embed_signup {
    position: relative;
    color: black !important;
    clear: left !important;
    font: 1rem 'ZCOOL XiaoWei', sans-serif !important;
    width: 100% !important;
    left: unset !important;
  }

  .Newsletter-container h1 {
      color: #fff;
      position: relative;
      font-size: 1.5rem;
      padding: 0;
      text-align: unset;
      width: 47%;
  }

  #mc_embed_signup .button {
    position: relative;
    background-color: #020231 !important;
    padding: 2% !important;
    font-size: 1rem !important;
    width: 50% !important;
    text-align: center !important;
    height: 4vh !important;
    line-height: unset !important;
    margin: auto !important;
  }

  .Footer{
  width: 90%;
  }

  .Footer-container {
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }

  .footer-section {
    margin-bottom: 0%;
    width: 25%;
  }

  .logos {
    margin-top: 6%;
  }

  input#checkBox {
    float: left;
    width: 2vw;
    height: 3vh;
    margin-right: 1rem;
  }

}

@media (min-width: 1440px) {

  .HeroSection, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    margin-top: 8%;
  }

  .Navbar, .HeroSection, .MainContent, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    width: 90%;
  }
  
  img.pro {
      height: 30px;
      position: absolute;
      right: -45px;
      top: -6px;
      transform: scale(.7);
      width: 60px;
  }

  .Navbar ul {
      display: flex;
      font-size: 1.6em;
      list-style-type: none;
      margin-left: 0px;
      padding: 0;
  }

  .Navbar img {
    width: 6vw;
  }

  .non-Pro {
    cursor: pointer;
    margin: 0px 2rem;
  }

  h1 {
    font-size: 3em;
  }

  .P-Text {
    padding: 5% 0px 5%;
    font-size: 1.5rem;
  }

  img.contentbox {
      width: 37vw;
  }

  .Navbar button {
      background-color: #68e0e9;
      border: none;
      border-radius: 50px;
      color: black;
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: bold;
      height: 7vh;
      width: 10vw;
  }

  img.DesktopContentbox {
    width: 16vh;
    position: relative;
    right: 55%;
  }

  .DesktopQuarter2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .DesktopHerocontainer {
      padding: 0 0% 0 6%;
      margin: unset;
      margin-top: 10%;
  }

  img.image-5 {
    display: block;
    width: 35vw;
    position: relative;
    left: 7%;
  }

  .DesktopQPARA {
      margin-bottom: 7%;
  }

  h1#main {
      font-size: 3em;
  }

  p#main {
      font-size: 1.4rem;
      padding-bottom: 5%;
  }

  .container1, .container2 {
    width: 70%;
  }

  .container1 .half img, .container2 .half img {
    width: 75%;
  }

  .SecondaryContent p {
      font-size: 1.3rem;
      font-weight: 500;
      text-align: center;
  }

  .left-div, .right-div {
      border: 2px solid #000;
      border-radius: 15px;
      box-sizing: border-box;
      height: 275px;
      padding: 3%;
      width: 45%;
  }

  .Contact-Us img {
      position: relative;
      width: 44%;
      bottom: -0.5vh;
  }

  .Testimonials {
      padding: 0 5% 0 5%;
      font-size: 1.3rem;
  }

  .FAQ {
      font-size: larger;
  }

  span.question-text {
      font-size: 1.3rem;
  }

  span.question-text {
      font-size: 1.3rem;
  }

  span.question-text {
      font-size: 1.3rem;
  }

  .Newsletter-container {
      background-color: #f4743b;
      border-radius: 15px;
      height: -moz-fit-content;
      height: fit-content;
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
  }
  
  div#mc_embed_shell {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
  }

  #mc_embed_signup {
    position: relative;
    background: unset !important;
    color: black !important;
    clear: left !important;
    font: 1rem 'ZCOOL XiaoWei', sans-serif !important;
    width: 100% !important;
    left: unset !important;
  }

  .Newsletter-container h1 {
      color: #fff;
      position: relative;
      font-size: 2rem;
      padding: 0;
      text-align: unset;
      width: 60%;
  }

  #mc_embed_signup .button {
    position: relative;
    background-color: #020231 !important;
    padding: 2% !important;
    font-size: 1rem !important;
    width: 30% !important;
    text-align: center !important;
    height: 4vh !important;
    line-height: unset !important;
    margin: auto !important;
  }

  .Footer-container {
      margin: auto;
      width: -moz-fit-content;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
  }

  .logos {
    margin-top: 5%;
  }
}

@media (min-width: 1470px){
    img.DesktopContentbox {
    position: relative;
    right: 55%;
    width: 25vh;
  }
}

@media (min-width: 1500px) {

  .HeroSection, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    margin-top: 7%;
  }

  .Navbar, .HeroSection, .MainContent, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    width: 90%;
  }

  .Navbar {
    align-items: center;
    background-color: #fff;
    display: flex;
    margin-bottom: 0;
  }

  .Navbar img {
    width: 5vw;
  }

  img.pro {
    height: 30px;
    position: absolute;
    right: -45px;
    top: -6px;
    transform: scale(.7);
    width: 60px;
  }

  .non-Pro {
    cursor: pointer;
    margin: 0px 3rem;
  }

  .Navbar button {
      background-color: #68e0e9;
      border: none;
      border-radius: 50px;
      color: black;
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: bold;
      height: 5vh;
      width: 8vw;
  }

  img.contentbox {
      width: 30vw;
  }

  .FormContainer {
      position: relative;
      width: 45%;
      left: 10%;
      top: 5vh;
  }

  .HeroCTA {
      background-color: #020231;
      border: 1px #020231;
      border-radius: 50px;
      color: #fff;
      font-size: 1.3rem;
      width: 9vw;
      padding: 2%;
  }

  .DesktopHerocontainer {
    padding: 0% 5% 0 12%;
    display: flex;
    flex-direction: row;
    margin-top: 7%;
    margin-bottom: 5%;
    align-items: center;
    gap: 5%;
    justify-content: space-between;
  }

  .DesktopQPARA {
    margin-bottom: 10%;
  }

  .DesktopQuarter2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .DesktopQuarter3 {
      position: relative;
      right: 6%;
  }

  img.image-5 {
      display: block;
      width: 26vw;
      position: unset;
  }

  img.DesktopContentbox {
    width: 20vh;
    position: relative;
    right: -20%;
  }

  .MainHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    gap: 5%;
    width: 90%;
  }

  .container1 .half img, .container2 .half img {
    width: 65%;
  }

  .left-div, .right-div {
    border: 2px solid #000;
    border-radius: 15px;
    box-sizing: border-box;
    height: 275px;
    padding: 3%;
    width: 30%;
    margin-top: 5%;
  }

  .li-Pro {
    margin-right: 5rem;
  }

  .faq-container {
    margin: 5% auto auto;
    width: 100%;
  }

  .Testimonials h1, h3 {
    text-align: center;
  }

  .FAQ h1 {
    font-size: 3rem;
  }

  .toggle-button img {
    width: 25px;
  }

  .Newsletter-container {
      background-color: #f4743b;
      border-radius: 15px;
      height: -moz-fit-content;
      height: fit-content;
      width: 60%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
  }
  
  div#mc_embed_shell {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: fit-content;
    align-items: center;
  }

  #mc_embed_signup {
    position: relative;
    background: unset !important;
    color: black !important;
    clear: left !important;
    font: 1rem 'ZCOOL XiaoWei', sans-serif !important;
    width: 100% !important;
    left: unset !important;
  }

  .Newsletter-container h1 {
      color: #fff;
      position: relative;
      font-size: 1.7rem;
      padding: 0;
      text-align: unset;
      width: 65%;
  }

  #mc_embed_signup .button {
    position: relative;
    background-color: #020231 !important;
    padding: 2% !important;
    font-size: 1rem !important;
    width: 30% !important;
    text-align: center !important;
    height: 3vh !important;
    line-height: unset !important;
    margin: auto !important;
  }

  .logos {
    margin-top: 4%;
  }

}

@media (min-width: 1600px){
  .DesktopHerocontainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: space-between;
    margin-bottom: 5%;
    margin-top: 7%;
    padding: 0px 13% 0 16%;
  }
}

@media (min-width: 2560px) {

  .DesktopHerocontainer, .MainContent, .SecondaryContent, .Contact-Us, .Testimonials, .FAQ, .Newsletter, .Footer {
    width: 100%;
  }

  .Navbar img {
    width: 4vw;
  }

  .answer, .question-text, p {
    font-size: 1.7rem !important;
  }

  .HeroText {
    font-size: 1.5rem;
    width: 55%;
  }

  h1#main {
    font-size: 4em;
  }

  p#main {
    font-size: 1.7rem;
  }

  .HeroImageButton {
    padding-top: 8%;
  }

  img.image-5 {
    width: 25vw;
  }

  .container1, .container2 {
    width: 70%;
  }

  .container1 .half img, .container2 .half img {
    width: 60%;
  }

  .container1 .half_text p, .container2 .half_text p {
    font-size: 1.7em !important;
  }

  .container1 .half_text h1, .container2 .half_text h1 {
    font-size: 3em;
  }

  #cta1, .container2 #cta1 {
    font-size: 1.6rem;
    width: 12vw;
  }

  .SecondaryContent h1 {
    font-size: 4rem;
  }

  .SecondaryContent p {
    font-size: 1.7rem;
  }

  .left-div, .right-div {
    height: 300px;
    width: 30%;
  }

  .left-div img, .right-div img {
    width: 10%;
  }

  .Contact-Us img {
    position: relative;
    width: 44%;
    bottom: -0.5vh;
    height: 55vh;
    right: 7%;
  }

  .FormContainer {
    font-size: 1.5rem;
  }

  .form-row input, .form-row textarea {
    font-size: 1.5rem;
  }

  label.ATC {
    font-size: 1.5rem;
  }

  .form-container button {
    font-size: 2rem;
    height: 6vh;
    width: 15%;
    margin: auto;
  }

  .Testimonials h1 {
    font-size: 4rem;
  }

  .Testimonials h3 {
    font-size: 2rem;
    text-align: center;
  }

  .Testimonials p {
    font-size: 1.7rem;
  }

  span.question-text {
    font-size: 1.8rem;
  }

  .answer {
    font-size: 1.6rem;
  }

  .faq-item {
    margin-bottom: 80px;
  }

  .Newsletter-container {
    font-size: 2rem;
  }

  #mc_embed_signup .button {
    font-size: 1.5rem !important;
    height: 4vh !important;
    width: 23% !important;
  }

  .Newsletter-container h1 {
    color: #fff;
    position: relative;
    font-size: 2.5rem;
    padding: 0;
    text-align: unset;
    width: 65%;
  }

  .Footer{
    width: 100%;
  }

  .Footer h1, h3 {
    text-align: unset;
  }

  .Footer-container {
    font-size: 1.5rem;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.footer-logo {
    width: 200px;
    margin-bottom: 20px;
  }

  .logos {
    margin-top: 3%;
  }

}